
<template>
  <div class="wrapper">
    <img class="image" :src="detail.pic" alt="">
    <div class="info">
      <div class="text">
        描述语：{{ detail.refer }}
      </div>
      <div>
        <div class="tag style1" @click.stop="again">
          再画一次
        </div>
      <!-- <div class="tag ">
          添加标题
          </div> -->
        <div @click.stop="add_hide" class="checkbox" :class="{ 'active': detail.hide == 1 }">
        </div>
        <span class="text1">分享及投稿时隐藏描述</span>
      </div>
    </div>
    <div class="user-info">
      <img class="photo" :src="detail.head" alt="">
      <div class="text2">
        <div class="text3">
          {{ detail.name }}
        </div>
        <span>风格：{{ detail.style }}</span><span v-if="detail.artist">艺术家：{{ detail.artist }}</span><span>生成时间：{{ detail.createtime | formatData }}</span>
      </div>
      <div class="opt">
        <div class="icon-wrapper" data-text="投稿">
          <img v-if="detail.together" @click.stop="add_together" class="icon" src="../../assets/images/share-active.png" alt="">
          <img v-else @click.stop="add_together" class="icon" src="../../assets/images/share.png" alt="">
        </div>
        <div class="icon-wrapper" data-text="下载">
          <img @click.stop="downloadIamge(detail.text)" class="icon" src="../../assets/images/xiazai.png" alt="">
        </div>
        <div class="icon-wrapper" data-text="分享">
          <img @click.stop="copy" class="icon" src="../../assets/images/fenxiang.png" alt="">
        </div>
        <div class="icon-wrapper" data-text="删除">
          <img @click.stop="del" class="icon" src="../../assets/images/del.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { add_hide, add_together } from "@/api/index";
export default {
  name: "Painting",
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      detail: this.data
    };
  },
  filters: {
    formatData(e){
      if (!e) {
        return ''
      }
      const date = e.split(' ')[0].split('-')
      return date.join('.')
    }
  },
  mounted() {

  },
  watch: {
    data() {
      this.detail = this.data
    }
  },
  methods: {
    downloadIamge( name) {
      const imgsrc = process.env.VUE_APP_BASE_API + '/index.php/aipic/Pic/download?pid=' + this.detail.id + '&uid=' + this.$store.state.app.userInfo.id + '&token=' + this.$store.state.app.userInfo.token
      //下载图片地址和图片名
      var image = new Image()
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous")
      image.onload = function () {
        var canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据'
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window
        }); // 创建一个单击事件
        a.download = name || "photo" // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    add_together() {
      const together = this.detail.together ? 0 : 1
      add_together({
        pid: this.detail.id,
        together
      }).then(e => {
        this.detail.together = together
      })
    },
    copy() {
      const routeData = this.$router.resolve({
        path: '/detail?id=' + this.detail.id
      });
      this.$copyText(location.origin + '/' + routeData.href).then(
        e => {
          this.$toast('复制成功');
        },
        e => {
          this.$toast('复制失败');
        }
      )
    },
    add_hide() {
      const hide = this.detail.hide ? 0 : 1
      add_hide({
        pid: this.detail.id,
        hide: hide
      }).then(e => {
        this.detail.hide = hide
      })
    },
    del() {
      this.$emit('del')
    },
    again() {
      this.$emit('again')
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  background: #1E293B;

  .image {
    // height: 836px;
    width: 100%;
  }

  .info {
    border-bottom: 1px solid #4F5764;
    padding: 40px 0 30px 0;
    margin: 0 40px;

    .tag {
      display: inline-block;
      width: 134px;
      height: 52px;
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      font-size: 22px;
      color: #FFFFFF;
      border: 1px solid #3B83F6;
      margin-right: 20px;
      cursor: pointer;

      &.style1 {
        line-height: 52px;
        background: #3B83F6;
        border: none;
      }
    }

    .checkbox {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 8px;
      border: 1px solid #3B83F6;
      overflow: hidden;
      margin-top: 12px;
      vertical-align: top;
      margin-right: 17px;

      &.active::after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: 5px;
        top: 5px;
        background-color: #3B83F6;
        border-radius: 6px;
      }
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 30px 0;
    margin: 0 40px;

    .photo {
      width: 60px;
      height: 60px;
      margin-right: 30px;
      border-radius: 50%;
    }

    .text2 {
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 33px;
      flex: 1;

      span {
        margin-right: 20px;
      }
    }

    .text3 {
      margin-bottom: 10px;
    }

    .opt {
      font-size: 0;
      margin-top: 46px;

      .icon-wrapper {
        position: relative;
        display: inline-block;
        margin-left: 37px;
        width: 26px;

        &:first-child {
          margin-left: 0;
          // width: 30px;
        }

        &:hover::before {
          position: absolute;
          content: attr(data-text);
          font-size: 26px;
          color: #FFFFFF;
          white-space: nowrap;
          top: -70px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 4px;
          padding: 10px 15px;
          min-width: 10px;
          background: #32425E;
        }

        .icon {
          width: 100%;
          vertical-align: middle;
        }
      }

    }
  }

}

.text,
.text1 {
  font-size: 26px;
  color: #FFFFFF;
  line-height: 54px;
}

.text {
  font-weight: 600;
  margin-bottom: 20px;
}</style>
