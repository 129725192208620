import router from "./router";
import store from './store'
const whiteList = ['/', '/home', '/bind', '/about', '/product', '/create', '/art']

router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    // if (to.path == '/') {
    //   const scriptInfo = document.createElement("script")
    //   scriptInfo.type = "text/javascript"
    //   scriptInfo.setAttribute("data-callType","callScript")
    //   scriptInfo.src = "/ad.js"
    //   document.body.appendChild(scriptInfo)
    //   next()
    // } else if (window.TencentGDT && window.TencentGDT.length) {
    //   window.TencentGDT = []
    //   const routeData = router.resolve({
    //     path: to.fullPath
    //   })
    //   location.href = routeData.href
    //   location.reload()
    // } else {
    //   next()
    // }
    next()
  } else if (!store.getters.token) {
    next('/')
    store.dispatch('setShowLogin',true)
  } else {
    // 除首页外清除广告
    // if (window.TencentGDT && window.TencentGDT.length) {
    //   window.TencentGDT = []
    //   const routeData = router.resolve({
    //     path: to.fullPath
    //   })
    //   location.href = routeData.href
    //   location.reload()
    // } else {
    //   next()
    // }
    next()
  }
})
