<template>
  <div class="top-bar">
    <div class="logo" @click="goPage('/')">
      画梦空间
    </div>
    <ul class="menu">
      <li @click="goPage(item.path)" v-for="item in tabs" :key="item.path" class="menu-item "
        :class="{ 'is-active': item.path == $route.path }">
        {{ item.name }}</li>
    </ul>
    <div class="right">
      <div v-if="!userInfo.id" class="btn" @click="login">登录</div>
      <div v-if="userInfo.id" class="btn" @click="goPage('/create')">画梦</div>
      <div v-if="userInfo.id" class="btn style1" @click="goPage('/user?tab=2')"><img src="../../../assets/images/star.png"
          alt=""><span>{{ $store.state.app.userInfo.quantity }}</span></div>
      <div v-if="userInfo.id" class="photo" >
        <img @click="goPage('/user')" :src="$store.state.app.userInfo.head" alt="">
        <div class="menu-v">
          <div class="menu-v-content">
            <div @click="goPage('/user')" class="menu-v-item">
              个人中心
            </div>
            <div @click="logout" class="menu-v-item">
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm v-if="showLogout" message="是否确认退出登录？" @close="close" @confirm="confirm"></confirm>
  </div>
</template>

<script>
import confirm from "../../../components/confirm";
// import { getUserInfo } from "../../../utils/auth";
// import { get_data } from "@/api/account";
export default {
  name: "TopBar",
  components: { confirm },
  data() {
    return {
      tabs: [{
        name: '梦开始',
        path: '/home'
      }, {
        name: '梦空间',
        path: '/art'
      }, {
        name: '画梦',
        path: '/create'
      }, {
        name: '记忆',
        path: '/portfolio'
      }],
      userInfo: this.$store.state.app.userInfo,
      showLogout: false
    };
  },
  created() {
    this.getUserInfo()
  },
  computed: {

  },
  methods: {
    logout(){
      this.showLogout = true
    },
    confirm(){
      this.userInfo = {}
      this.close()
      this.$store.dispatch('setUserInfo', {}).then(e => {
        this.$router.push({
          path: '/'
        })
      })
    },
    close(){
      this.showLogout = false
    },
    login() {
      this.$store.dispatch('setShowLogin', true)
    },
    goPage(path) {
      this.$router.push({
        path
      })
    },
    getUserInfo() {
      this.$store.dispatch('getUserInfo').then(e => {
        this.userInfo = e
      })

      // const userInfo = getUserInfo()
      // if (!userInfo.id) {
      //   return
      // }
      // get_data({
      //   cid: userInfo.id
      // }).then((e) => {
      //   const { data, code } = e
      //   if (code == "200") {
      //     const info = Object.assign(userInfo,data)
      //     this.userInfo = info
      //     this.$store.dispatch('setUserInfo', info)
      //   }
      // });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-bar {
  height: 100px;
  background: #1E293B;
  padding: 0 90px;

  .logo {
    line-height: 100px;
    float: left;
    color: #FFFFFF;
    font-size: 30px;
    cursor: pointer;
  }

  .menu {

    font-size: 26px;
    display: inline-block;
    line-height: 100px;

    .menu-item {
      color: #7887A7;
      display: inline-block;
      margin-left: 100px;
      cursor: pointer;

      &.is-active {
        color: #FFFFFF;
      }
    }
  }

  .right {
    float: right;
    padding-top: 25px;

    .btn {
      border-radius: 8px;
      background: #3B83F6;
      color: #FFFFFF;
      line-height: 53px;
      text-align: center;
      display: inline-block;
      width: 122px;
      margin-left: 30px;
      vertical-align: top;
      cursor: pointer;

      &.style1 {
        background: rgba(0, 98, 255, 0.18);
        border: 1px solid #3B83F6;
        display: inline-block;
        box-sizing: border-box;
        line-height: 51px;

        img {
          width: 30px;
          margin-right: 17px;

        }

        span {}
      }
    }

    .photo {
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-left: 30px;
      margin-top: -5px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }

      .menu-v {
        position: absolute;
        right: 100px;
        top: 70px;
        width: 342px;
        display: none;
        padding-top: 40px;
        z-index: 9;
        &:hover {
          display: block;
        }

        .menu-v-content {
          border-radius: 8px;
          background: #1E293B;

          .menu-v-item {
            position: relative;
            line-height: 37px;
            padding: 23px 0;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
            &:not(:last-child)::after {
              position: absolute;
              content: '';
              bottom: 0;
              left: 22px;
              right: 22px;
              height: 1px;
              background: #495569;

            }
          }
        }

      }

      &:hover {
        .menu-v {
          display: block;
        }
      }
    }
  }

}</style>
