
<template>
    <div class="swiper">
        <div class="swiper_main" :style="{left: idx * -100 + '%'}" :class="{ 'transition': transition}">
            <!-- <div class="page">点击One</div>
            <div class="page">点击Two</div>
            <div class="page">点击Three</div>
            <div class="page">点击Four</div> -->
            <slot></slot>
        </div>
        <!-- <div class="swiper_main" :style="{left: idx1 * 100 + '%'}">
            <div class="page">点击One</div>
        </div> -->
    </div>
</template>
<script>
import { add_hide } from "@/api/index";
export default {
    name: "Swiper",
    props: {
        pageNum: {
            type: Number,
            default : 0
        }
    },
    data() {
        return {
            idx: 0,
            time: null,
            transition: true
        };
    },
    created(){
        // this.idx = this.data.length
    },
    mounted() {
        if (this.pageNum > 2) {
            this.init()
        }
        
    },
    methods: {
        init(){
          this.transition = true
          this.time = setTimeout(e => {
              this.idx++
              if(this.idx === this.pageNum - 1) {
                setTimeout(e => {
                  this.transition = false
                  this.idx = 0
                  setTimeout(e => {
                    this.idx++
                    this.init()
                  },1000)
                },1000)
              } else {
                this.init()
              }
          },2000)
        }
    },
    beforeDestroy(){
        this.time && clearInterval(this.time)
    }
};
</script>

<style lang="scss" scoped>
.swiper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
}

.swiper_main {
    display: flex;
    height: 100%;
    position: absolute;
    left: 0;

}
.transition{
  transition: all 0.5s linear;
}

</style>
