
<template>
    <div  class="page" >
        <slot></slot>
    </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
    .page{
      position: relative;
        // flex: 0 0 200px;
        // width: 200px;
        }
</style>
