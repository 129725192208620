<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data() {
    return { };
  },
  mounted() {
    
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  // -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  // -webkit-user-select: none; /*webkit浏览器*/
  // -khtml-user-select: none; /*早期浏览器*/
  // -moz-user-select: none; /*火狐*/
  // -ms-user-select: none; /*IE10*/
  // user-select: none;
  // -webkit-text-size-adjust: none
}
input {
  -webkit-user-select:auto; /*webkit浏览器*/
}
html,
body {
  width: 100%;
  min-height: 100%;
  background-color: var(--background);
}
body {
  font-size: 26px!important;
}
.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix {
  display: inline-block;
}

.clearfix {
  display: block;
}


.app-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  .app-main {
    flex: 1;
    position: relative;
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;
    // &.no-scroll{
    //   overflow: hidden;
    // }
    height: calc(100% - 98px);
    height: -webkit-calc(100% - 98px);
    height: -moz-calc(100% - 98px);
  }
  .app-mine-page {
    height: 100%;
  }
}

</style>
