import toast from './toast'
import { showLoading, hideLoading } from './loading'
export default {
  install(Vue) {
    // 缓存对象
    Vue.prototype.$toast = toast
    Vue.prototype.$showLoading = showLoading
    Vue.prototype.$hideLoading = hideLoading
  }
}
