const UserInfoKey = 'User-Info'

export function getUserId() {
  return getUserInfo().userId
}

export function getUserInfo() {
  return getLocalStorage(UserInfoKey) ? JSON.parse(getLocalStorage(UserInfoKey)) : {}
}

export function setUserInfo(value) {
  setLocalStorage(UserInfoKey, JSON.stringify(value))
}

export function removeUserInfo() {
  return removeLocalStorage(UserInfoKey)
}

export function getLocalStorage(key) {
  return localStorage.getItem(key)
}

export function setLocalStorage(key, value) {
  return localStorage.setItem(key, value)
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(key)
}
