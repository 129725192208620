const cache = {
    timeout: null,
    node: null
}
function hide() {
    if (!cache.node) {
        cache.node = document.getElementById('toast')
    }

    cache.node.style.display = 'none'
    cache.timeout = null
    // document.body.removeChild(toastNode);
}
function toast(msg) {
    if (cache.timeout) {
        clearTimeout(cache.timeout)
        cache.timeout = null
    }
    if (!cache.node) {
        var toastNode = document.createElement('div')
        toastNode.innerHTML = `<span class="toast-text">${msg}</span>`
        toastNode.id = 'toast'
        toastNode.setAttribute('class', 'toast')
        toastNode.style.display = 'block'
        document.body.appendChild(toastNode)
        cache.node = toastNode
    } else {
        cache.node.innerHTML = `<span class="toast-text">${msg}</span>`
        cache.node.style.display = 'block'
    }

    cache.timeout = setTimeout(e => {
        hide()
    }, 2000)
}
export default toast
