import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import "./assets/app.css";
import "amfe-flexible";
import store from "./store";
import "./permission.js";
import VueClipBoard from 'vue-clipboard2'
import plugins from './plugins' 

Vue.use(plugins)
Vue.use(VueClipBoard)
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
