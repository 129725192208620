<template>
  <div class="app-wrapper " :class="{'is-login1': $store.state.app.showLogin}">
   <TopBar />
    <div class="main-container">
      <app-main />
    </div>
    <Login v-if="$store.state.app.showLogin"/>
  </div>
</template>

<script>
import { AppMain, TopBar, AppFooter, Login } from "./components";

export default {
  name: "Layout",
  components: {
    AppMain,
    TopBar,
    AppFooter,
    Login
  },
  data() {
    return {
      showLogin: this.$store.state.app.showLogin
    };
  },
  created() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
  .app-wrapper{
    min-height: 100vh;

    &.is-login{
      height: 100vh;
      overflow: hidden;
    }
  }
  .main-container{
    min-height: calc(100vh - 329px );
  }
</style>
