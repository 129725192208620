import Vue from "vue";
import Router from "vue-router";
import Layout from "@/views/layout";
Vue.use(Router);
// 解决重复点击导航路由报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  // console.log("location", Vue.$router)
  // let routeData = Router.resolve(location);
  // window.open(routeData.href, '_blank');
  return originalPush.call(this, location).catch(err => err);
};

const vr = [{
  path: "/",
  component: Layout,
  redirect: "/",
  hidden: true,
  children: [{
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
    meta: {
      keepAlive: false,
      title: "home",
      icon: "home"
    }
  },{
    path: "/",
    name: "start",
    component: () => import("@/views/start"),
    meta: {
      keepAlive: false,
      title: "start",
      icon: "start"
    }
  },{
    path: "/art",
    name: "art",
    component: () => import("@/views/art"),
    meta: {
      keepAlive: false,
      title: "art",
      icon: "art"
    }
  },{
    path: "/create",
    name: "create",
    component: () => import("@/views/create"),
    meta: {
      keepAlive: false,
      title: "create",
      icon: "create"
    }
  },{
    path: "/user",
    name: "user",
    component: () => import("@/views/user"),
    meta: {
      keepAlive: false,
      title: "user",
      icon: "user"
    }
  },{
    path: "/clipboard",
    name: "clipboard",
    component: () => import("@/views/clipboard"),
    meta: {
      keepAlive: false,
      title: "clipboard",
      icon: "clipboard"
    }
  },{
    path: "/portfolio",
    name: "portfolio",
    component: () => import("@/views/portfolio"),
    meta: {
      keepAlive: false,
      title: "portfolio",
      icon: "portfolio"
    }
  },{
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail"),
    meta: {
      keepAlive: false,
      title: "detail",
      icon: "detail"
    }
  },{
    path: "/bind",
    name: "bind",
    component: () => import("@/views/user/bind"),
    meta: {
      keepAlive: false,
      title: "bind",
      icon: "bind"
    }
  },{
    path: "/about",
    name: "about",
    component: () => import("@/views/about"),
    meta: {
      keepAlive: false,
      title: "about",
      icon: "about"
    }
  },{
    path: "/product",
    name: "product",
    component: () => import("@/views/product"),
    meta: {
      keepAlive: false,
      title: "product",
      icon: "product"
    }
  }]
}];

const router = new Router({
  base: "/",
  routes: vr
});
// router.push = function push(location) {
//   let routeData = router.resolve(location);
//   // window.location.href = routeData.href;
//   mixins.methods.openOutNet(routeData.href);
//   // return originalPush.call(this, location).catch(err => err);
// };
export default router;
