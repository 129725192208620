<template>
  <div class="login-wrapper">
    <div class="mask">

    </div>
    <div class="wrapper">
      <div class="left">
        <!-- <img class="logo" src="../../../assets/images/topbanner.jpg" alt=""> -->
        <div class="logo-title">
          画梦空间
        </div>

        <div class="text3">
          画梦空间， <br>梦开始的地方
        </div>
        <div class="text4">
          AI画出梦想<br>分享你的AI绘画<br>展示你的与众不同
        </div>
      </div>
      <div class="right">
        <img class="close" @click="closeLogin" src="../../../assets/images/close.png" alt="">
        <div class="tab-wrapper">
          <div @click="change(0)" class="tab" :class="{ 'active': tabIdx == 0 }">扫码登录</div>
          <div @click="change(1)" class="tab" :class="{ 'active': tabIdx == 1 }">账号登录</div>
        </div>
        <template v-if="tabIdx == 0">
          <div class="code-wrapper">
            <wxlogin class="code" :appid="appid" :scope="scope" :redirect_uri="encodeURIComponent(redirect_uri)"
              href="data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KCi5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogICAgZGlzcGxheTogbm9uZTsKfQoKLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICBib3JkZXI6IG5vbmU7CiAgICB3aWR0aDogMTAwJTsKICAgIG1hcmdpbi10b3A6IDA7Cn0KCi5pbXBvd2VyQm94IC5zdGF0dXMgewogICAgZGlzcGxheTogbm9uZTsKfQoKLmltcG93ZXJCb3ggLmluZm8gewogICAgZGlzcGxheTogbm9uZTsKfQ==">
            </wxlogin>
            <!-- <img class="code" src="../../../assets/images/login-bg.png" alt=""> -->
            <div class="text">
              <img src="../../../assets/images/scan.png" alt="">
              <span>打开微信，扫一扫立即登录</span>
            </div>

          </div>
          <div class="text2">
            没有账号？<span @click="change(1)" style="cursor: pointer;">立即注册</span>
          </div>
        </template>
        <template v-if="tabIdx == 1">
          <div class="form-wrapper">
            <div class="form-item">
              <div class="form-label">
                手机号
              </div>
              <div class="form-content">
                <input v-model="form.mobile" class="input" type="text" placeholder="请输入您的手机号">
              </div>
            </div>
            <div class="form-item">
              <div class="form-label">
                验证码
              </div>
              <div class="form-content code">
                <input v-model="form.sms" class="input" type="text" placeholder="请输入验证码">
                <countdown v-if="showTiming" class="code-btn" v-slot="timeObj" :time="countDown"
                  @finish="finishCountDown">
                  {{ `${timeObj.secondsTime}` }}
                </countdown>
                <div class="code-btn" v-else @click="getCode">获取验证码</div>
                <!--  -->
              </div>
            </div>
            <div class="text2">
              未注册手机验证后自动登录，注册即代表同意<span @click="goExternalPage">《服务协议》</span>
            </div>
            <div class="login-btn" @click="randl">
              登录
            </div>
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import { sms, randl, get_wxl_intel } from "@/api/account";
import { countdown } from "../../../components";
import wxlogin from "vue-wxlogin";
export default {
  components: { countdown, wxlogin },
  data() {
    const routeData = this.$router.resolve({
      path: '/bind'
    })
    const redirect_uri = routeData.href
    return {
      tabIdx: 1,
      countDown: 60,
      showTiming: false,
      isLoading: false,
      form: {
        mobile: '',
        sms: ''
      },
      redirect_uri: location.origin + '/' + redirect_uri,
      appid: 'wxd8fac580784208f8',
      scope: 'snsapi_login'
    };
  },
  created() {
    // const routeData = this.$router.resolve({
    //   path: '/bind'
    // })
    // this.redirect_uri = routeData.href
    // this.get_wxl_intel()
  },
  computed: {

  },
  methods: {
    get_wxl_intel() {
      get_wxl_intel().then(e => {
        const { data } = e
      })
    },
    getCode() {
      if (!this.form.mobile || this.loading) {
        return
      }
      this.loading = true
      sms({
        mobile: this.form.mobile
      }).then((e) => {
        const { data, code } = e
        if (code == "200") {
          this.showTiming = true
        }
      }).finally(e => {
        this.loading = false
      })
    },
    finishCountDown() {
      this.showTiming = false
    },
    change(index) {
      this.tabIdx = index
    },
    closeLogin() {
      this.$store.dispatch('setShowLogin', false)
    },
    randl() {
      if (!this.form.mobile || !this.form.sms || this.loading) {
        return
      }
      this.loading = true
      randl(this.form).then((e) => {
        const { data, code } = e
        if (code == "200") {
          this.$store.dispatch('setUserInfo', data)
          location.reload()
        }
      }).finally(e => {
        this.loading = false
      })
    },
    goExternalPage(){
      window.open('https://www.paintdream.art/Disclaimer.pdf','_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #03102B;
  opacity: 0.72;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  position: relative;
  width: 1163px;
  height: 760px;
  background: #FFFFFF;
  display: flex;

  .left {
    width: 546px;
    height: 760px;
    background-image: url('../../../assets/images/login-bg.png');
    background-size: 100% 100%;
    padding: 80px 60px;
    box-sizing: border-box;

    .logo-title {
      font-size: 46px;
      color: #FFFFFF;
      line-height: 64px;
      letter-spacing: 1px;
      font-weight: normal;
    }

    .logo {
      height: 64px;
      width: 154px;
    }

    .text3 {
      font-weight: 600;
      color: #E5EFFF;
      line-height: 70px;
      font-size: 50px;
      margin-top: 68px;
    }

    .text4 {
      font-size: 30px;
      color: #ECEDEE;
      line-height: 42px;
      margin-top: 10px;
    }
  }

  .right {
    flex: 1;

    .close {
      position: absolute;
      top: 30px;
      right: 30px;
      height: 26px;
      width: 26px;
      cursor: pointer;
    }

    .tab-wrapper {
      margin-top: 92px;
      text-align: center;

      .tab {
        position: relative;
        display: inline-block;
        font-size: 28px;
        color: #666666;
        line-height: 50px;
        z-index: 1;
        cursor: pointer;

        &:first-child {
          margin-right: 50px;
        }

        &.active {
          color: #3B83F6;
          font-weight: 600;

          &::after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 8px;
            right: 8px;
            height: 13px;
            background: linear-gradient(180deg, #EAF4FF 0%, #EAF4FF 100%);
            border-radius: 2px;
            z-index: -1;
          }
        }
      }
    }

    .code-wrapper {
      width: 316px;
      height: 366px;
      text-align: center;
      margin: 72px auto 0;
      font-size: 0;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #E6E7FF;

      ::v-deep .code {
        width: 256px;
        height: 256px;
        margin-bottom: 25px;
        padding: 30px 30px 0 30px;

        iframe {
          width: 100%;
        }
      }

      .text {
        margin-top: 24px;

        img {
          height: 16px;
          width: 16px;
          margin-right: 16px;
          vertical-align: middle;
        }

        span {
          font-size: 15px;
          font-weight: 600;
          color: #999999;
          vertical-align: middle;
        }
      }

    }

    .text2 {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      color: #999999;
      line-height: 21px;
      margin-top: 35px;

      span {
        color: #307BFF;
        cursor: pointer;
      }
    }

  }

}

.form-wrapper {
  width: 506px;
  margin: 40px auto;

  .form-item {
    &:first-child {
      margin-bottom: 50px;
    }

    .form-label {
      font-size: 26px;
      font-weight: 600;
      color: #333333;
      line-height: 37px;
      margin-bottom: 11px;
    }

    .form-content {
      width: 506px;
      height: 68px;
      background: #FFFFFF;

      .input {
        font-size: 24px;
        font-weight: 400;
        color: #A4ABC6;
        line-height: 33px;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 20px;
        box-sizing: border-box;
        border: 1px solid #0263F1;
        border-radius: 6px;
      }

      &.code {
        position: relative;

        .input {
          padding-right: 150px;
        }

        .code-btn {
          position: absolute;
          font-size: 24px;
          color: #3B83F6;
          line-height: 33px;
          right: 20px;
          line-height: 66px;
          top: 0;
          cursor: pointer;
        }
      }
    }
  }

  .text2 {
    margin-top: 20px !important;
    text-align: left !important;
  }

  .login-btn {
    width: 506px;
    height: 68px;
    background: #3B83F6;
    border-radius: 6px;
    color: #FFFFFF;
    line-height: 68px;
    text-align: center;
    margin-top: 90px;
    cursor: pointer;
  }
}</style>
