<template>
  <div class="tab-container" id="tabContainer" :style="{ height: divHeight + 'px' }">
    <div class="tab-item" :style="{ height: contentWidth * item.scale + 'px'}" v-for="(item, index) in data" :key="index" @click="detail(item)">
      <img class="image" :src="item.pic" @load="rest" :style="{ height: contentWidth * item.scale + 'px'}"/>
      <div class="image-bg">
        <div class="info">
          <!-- <div class="text1">
            {{ item.text }}
          </div> -->
          <div v-if="!item.hide" class="text2">
            描述语：{{ item.refer }}
          </div>
          <div class="user-info">
            <img class="photo" :src="item.head" alt="">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="opt" @click.stop="piclikes(item)">
              <img v-if="item.myl" class="opt-icon" src="../../assets/images/zan-active.png" alt="">
              <img v-else class="opt-icon" src="../../assets/images/zan.png" alt="">
              <br>
              <!-- <img class="opt-icon" src="../../../src/assets/images/zan-active.png" alt=""> -->
              <span>{{ item.likes }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { piclikes } from "@/api/index";
export default {
  name: 'list',
  props: {
    list: {
      type: Array,
      default: () => { return [] }
    },
    disablePullUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      divHeight: 0,
      // fontSize: document.documentElement.style.fontSize.slice(0,-2)  / 192// 获取页面根字体大小缩放倍数
      fontSize: 1,
      contentWidth: 1
    };
  },
  watch: {
    list() {
      this.$nextTick(e => {
        this.rest();
      })
    }
  },
  computed: {
    data() {
      return this.list.map(e => {
        e.scale = 1
        if (e.resolution) {
          const ratios = e.resolution.split('×')
          if (ratios && ratios.length > 1) {
            e.scale = ratios[1] / ratios[0]
          }
        }
        return e
      })
    }
  },
  mounted() {
    this.rest(); //实现瀑布流
    if (!this.disablePullUp) {
      this.addEventListener()
    }
  },
  methods: {
    detail(item) {
      this.$router.push({
        path: '/detail',
        query: {
          id: item.id
        }
      })
    },
    piclikes(item) {
      piclikes({
        pid: item.id
      }).then(e => {
        const { data } = e
        item.myl = data.like
        item.likes += data.like ? 1 : -1
      })
    },
    addEventListener() {
      window.addEventListener('scroll', this.scrollFun, true)
    },
    removeEventListener() {
      window.removeEventListener('scroll', this.scrollFun, true)
    },
    scrollFun(e) {
      let scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset) //滑入屏幕滚动条滚动时，距离顶部的距离
      let windowHeitht = document.documentElement.clientHeight//能看到的页面的高度
      let scrollHeight = document.body.clientHeight //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht
      if (total + 10 > scrollHeight) {
        this.$emit('onBottom')
        //加载操作
      } else {
        console.log("还没有到底")
      }

    },
    rest() {
      this.waterFall("#tabContainer", ".tab-item")
    },
    waterFall(
      wrapIdName,
      contentIdName,
      columns = 3,
      columnGap = 60 * this.fontSize,
      rowGap = 60 * this.fontSize
    ) {


      // 获得内容可用宽度（去除滚动条宽度）
      const wrapContentWidth =
        document.querySelector(wrapIdName).offsetWidth
console.log(wrapContentWidth)
      // 间隔空白区域
      const whiteArea = (columns - 1) * columnGap
      // 得到每列宽度(也即每项内容宽度)
      const contentWidth = this.contentWidth = (wrapContentWidth - whiteArea) / columns
      // 得到内容项集合
      const contentList = document.querySelectorAll(contentIdName)

      // 成行内容项高度集合
      const lineConentHeightList = []

      for (let i = 0; i < contentList.length; i++) {
        // 动态设置内容项宽度
        contentList[i].style.width = contentWidth + "px"

        // 获取内容项高度
        const height = contentList[i].clientHeight


        if (i < columns) {
          // 第一行按序布局
          contentList[i].style.top = "0px"
          contentList[i].style.left = contentWidth * i + columnGap * i + "px"

          // 将行高push到数组
          lineConentHeightList.push(height)
        } else {
          // 其他行
          // 获取数组最小的高度 和 对应索引
          let minHeight = Math.min(...lineConentHeightList)
          let index = lineConentHeightList.findIndex(
            (listH) => listH === minHeight
          )

          contentList[i].style.top = (minHeight + rowGap) + "px"
          contentList[i].style.left = ((contentWidth + columnGap) * index) + "px"

          // 修改最小列的高度 最小列的高度 = 当前自己的高度 + 拼接过来的高度 + 行间距
          lineConentHeightList[index] += height + rowGap
        }
      }
      this.divHeight = (lineConentHeightList.sort((a, b) => {
        return b - a
      })[0] + 20)
    }
  },
  beforeDestroy() {
    this.removeEventListener()
  }
};
</script>

<style lang="scss" scoped>
/* 最外层大盒子 */
.tab-container {
  position: relative;
  overflow: hidden;
}

/* 每个小盒子 */
.tab-container {
  .tab-item {
    position: absolute;
    height: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background: white;
    /* 元素不能中断显示 */
    break-inside: avoid;

    .image {
      position: absolute;
      // left: 0;
      // top: 0;
      // bottom: 0;
      // right: 0;
      width: 100%;
      display: block;
      object-fit: contain;
    }

    &:hover {
      .image-bg {
        display: block;
      }
    }

    .image-bg {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba($color: #03102B, $alpha: 0.6);
      border-radius: 20px 20px 0px 0px;
      padding: 30px;
      .info {
        // position: absolute;
        // left: 30px;
        // right: 30px;
        // bottom: 30px;
        color: #FFFFFF;

        .text1 {
          font-size: 30px;
          font-weight: 600;
          line-height: 42px;
          margin-bottom: 9px;
        }

        .text2 {
          font-size: 26px;
          font-weight: 600;
          color: #FFFFFF;
          line-height: 1.5;
        }

        .user-info {
          display: flex;
          align-items: center;
          margin-top: 20px;

          .photo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
          }

          .name {
            flex: 1;
            font-size: 24px;
            padding: 0 30px;
          }

          .opt {
            flex: 0 0 auto;
            font-size: 24px;
            text-align: center;
            cursor: pointer;

            .opt-icon {
              width: 26px;
              height: 28px;
            }
          }
        }
      }

    }
  }
}</style>
