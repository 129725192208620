
<template>
    <div class="confirm">
        <div class="title">{{ message }}</div>
        <div class="btns">
            <div class="btn" @click="close">取消</div>
            <div class="btn active" @click="confirm">确认</div>
        </div>
    </div>
</template>
<script>

export default {
    name: "confirm",
    props: {
        message: {
            type: String,
            default: '系统提示'
        }
    },
    data() {
        return {

        };
    },
    created() {
    },
    mounted() {

    },
    methods: {
        close() {
            this.$emit('close')
        },
        confirm() {
            this.$emit('confirm')
        }
    }
};
</script>

<style lang="scss" scoped>
.confirm {
    position: fixed;
    z-index: 999;
    width: 565px;
    height: 258px;
    background: #102131;
    box-shadow: 0px 2px 40px 0px rgba(14, 46, 97, 1);
    border-radius: 20px;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 70px 0 50px;
    box-sizing: border-box;
    .title{
        font-size: 28px;
        color: #FFFFFF;
    }
    .btns {
        margin-top: 50px;
        .btn {
            display: inline-block;
            width: 106px;
            height: 48px;
            background: #32425E;
            border-radius: 5px;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 48px;
            cursor: pointer;
            &.active{
                background:#3B83F6;
                margin-left: 30px;
            }
        }
    }
}
</style>
