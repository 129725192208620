<template>
  <div class="app-footer-wrapper">
    <div class="app-footer">
      <div class="item ">
        <div class="text4">画梦空间</div>
        <div class="text">邮箱：364286393@qq.com</div>
        <div class="text">企业微信：364286393@qq.com</div>
      </div>

      <div class="item">
        <div class="text1">
          产品导航
        </div>
        <div class="text2" @click="goPage('/about')">
          关于我们
        </div>
        <div class="text2" @click="goPage('/product')">
          产品服务
        </div>
        <div class="text2" @click="goExternalPage">
          服务协议
        </div>

      </div>
      <div class="item">
        <!-- <div class="text1" style="text-align: center;">
          小程序
        </div> -->
        <img class="img" src="../../../assets/images/wx-code.png" alt="">
      </div>
      <div class="item last">
        <div class="text5">
          <img class="icon1" src="../../../assets/images/icon1.png" alt=""> 微信搜一搜
        </div>
        <div class="search-wrapper">
          <img class="search" src="../../../assets/images/search.png" alt="">
          画梦空间
        </div>

      </div>
    </div>
    <div class="text3"><a style="cursor: pointer;color: #FFFFFF;text-decoration-line: none;" href="https://beian.miit.gov.cn/">京ICP备19013529号-5</a> @2023盈萱科技（北京）有限公司 </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {

  },
  methods: {
    goPage(path) {
      this.$router.push({
        path
      })
    },
    goExternalPage() {
      window.open('https://www.paintdream.art/Disclaimer.pdf', '_blank')
    }
  }
};
</script>

<style lang="scss" scoped>
.app-footer-wrapper {
  overflow: hidden;
  background: linear-gradient(312deg, #102131 0%, #26549B 82%, #3B83F6 100%);
}

.app-footer {
  display: flex;

  // height: 517px;
  padding: 0 155px 0 123px;

  .item {
    flex: 0 0 auto;
    padding: 0 130px;
    text-align: right;
    color: #FFFFFF;
    margin-top: 120px;
    height: 258px;
    white-space: nowrap;

    &:first-child {
      text-align: left;
      padding: 0;
      flex: 1;
    }

    &:last-child {
      padding-right: 0;
      text-align: center;
      padding-left: 48px;
      padding-top: 48px;
    }

    &:nth-child(3) {
      text-align: center;
      font-size: 0;
      border-left: 1px solid #758EB5;
      padding-right: 0;

      .text1 {
        font-size: 38px;
        // margin-bottom: 32px;
      }
    }
  }

  .img {
    width: 142px;
    margin-top: 56px;
  }

  .text {
    font-size: 30px;
    margin-top: 20px;
    color: #F0F0F0;
  }

  .text1 {
    font-size: 38px;
    margin-bottom: 40px;
  }

  .text2 {
    font-size: 30px;
    color: #F0F0F0;
    margin-top: 22px;
    cursor: pointer;
  }

  .text4 {
    margin-bottom: 40px;
    font-size: 48px;
  }

  .text5 {
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 38px;
    margin-bottom: 26px;
  }

}

.text3 {
  text-align: center;
  font-size: 24px;
  color: #F0F0F0;
  line-height: 33px;
  margin-bottom: 60px;
  margin-top: 90px;
}

.icon1 {
  width: 54px;
  height: 53px;
  margin-right: 26px;
}

.search-wrapper {
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  width: 279px;
  height: 68px;
  font-size: 34px;
}

.search {
  width: 28px;
  height: 28px;
  margin-right: 18px;
}</style>
