const cache = {
    timeout: null,
    node: null,
    duration: 200
}
export function hideLoading() {
    if (!cache.node) {
        cache.node = document.getElementById('loading')
    }
    if (cache.node) {
        cache.node.style.display = 'none'
    }
    
    // cache.timeout = setTimeout(e => {
    //     cache.node.style.display = 'none'
    //     clearTimeout(cache.timeout)
    //     cache.timeout = null
    // }, cache.duration)
    
    // document.body.removeChild(node);
}

export function showLoading(opt={}) {
    cache.duration = opt.duration === undefined ? 200 : opt.duration
    const img = require(`../assets/images/${opt.icon || 'loading.png'}`)
    if (cache.timeout) {
        clearTimeout(cache.timeout)
        cache.timeout = null
    }
    if (!cache.node) {
        var node = document.createElement('div')
        node.innerHTML = `<div class="loading-box" style="padding: 0.6rem 0">
            <img src="${img}" />
            <p>${opt.msg || '努力加载中'}</p>
        </div>`
        node.id = 'loading'
        node.setAttribute('class', 'loading')
        node.style.display = 'flex'
        document.body.appendChild(node)
        cache.node = node
    } else {
        cache.node.innerHTML = `<div class="loading-box" style="padding: 0.6rem 0">
        <img src="${img}" />
            <p>${opt.msg || '努力加载中'}</p>
        </div>`
        cache.node.style.display = 'flex'
    }
}
