import axios from "axios";
import store from "../store";
// import { getToken } from "@/utils/auth";
// import Tool from "@/utils/Tool";
// import host from "@/config";
import toast from '../plugins/toast'
let oneceRefreshToken = false;
const cashRequest = {}; // 缓存请求

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
});

service.interceptors.request.use(
  config => {
    if (config.method === "post") {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    const reg = new RegExp(config.baseURL, "ig");
    const cfKey = config.url.replace(reg, "");
    cashRequest[cfKey] = config;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.data.code != 200) {
      if (response.data.code == 401) {
        store.dispatch("setUserInfo", {}).then(() => {
          location.reload();
        });
      } else {
        toast(response.data.msg)
        return Promise.reject(response.data)
      }
    }
    return response.data;
  },
  error => {
    if (error && error.response) {
    }
    return Promise.reject(error);
  }
);

export default service;
