var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-count-down"},[_c('div',{staticClass:"content"},[_vm._t("default",null,null,{
      d: _vm.days,
      h: _vm.hours,
      m: _vm.mins,
      s: _vm.seconds,
      hh: `00${_vm.hours}`.slice(-2),
      mm: `00${_vm.mins}`.slice(-2),
      ss: `00${_vm.seconds}`.slice(-2),
      secondsTime: _vm.secondsTime
    })],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }