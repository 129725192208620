import request from "@/utils/request";
import requestUpload from "@/utils/requestUpload";
import { download } from "@/utils/request";

//腾讯图生图风格
export const style_pic = params => {
  return request({
    url: "/index.php/aipic/Ai/style_pic",
    method: "get",
    params
  });
};

//腾讯文生图风格
export const style_txt = params => {
  return request({
    url: "/index.php/aipic/Ai/style_txt",
    method: "get",
    params
  });
};

//百度AI绘图风格
export const style_bd = params => {
  return request({
    url: "/index.php/aipic/Ai/style_bd",
    method: "get",
    params
  });
};

//腾讯分辨率列表
export const resolution_tx = params => {
  return request({
    url: "/index.php/aipic/Ai/resolution_tx",
    method: "get",
    params
  });
};

//百度分辨率列表
export const resolution_bd = params => {
  return request({
    url: "/index.php/aipic/Ai/resolution_bd",
    method: "get",
    params
  });
};

export const upload = data => {
  return requestUpload({
    url: "/index.php/aipic/Ai/upload",
    method: "post",
    data
  });
};

//腾讯文生图
export const txt_to_pic = params => {
  return request({
    url: "/index.php/aipic/Ai/txt_to_pic",
    method: "get",
    params
  });
};


//腾讯图生图
export const pic_to_pic = params => {
  return request({
    url: "/index.php/aipic/Ai/pic_to_pic",
    method: "get",
    params
  });
};


//百度AI绘图
export const ernie_vilg = params => {
  return request({
    url: "/index.php/aipic/Ai/ernie_vilg",
    method: "get",
    params
  });
};

//最热图片列表
export const hottest_list = params => {
  return request({
    url: "/index.php/aipic/Publicpic/hottest_list",
    method: "get",
    params
  });
};

//最新图片列表
export const newest_list = params => {
  return request({
    url: "/index.php/aipic/Publicpic/newest_list",
    method: "get",
    params
  });
};

//关注/取消用户
export const careuser = params => {
  return request({
    url: "/index.php/aipic/Userin/careuser",
    method: "get",
    params
  });
};

//获取我的全部列表
export const get_all_list = params => {
  return request({
    url: "/index.php/aipic/Pic/get_all_list",
    method: "get",
    params
  });
};

// //获取我的全部列表
// export const get_all_list = params => {
//   return request({
//     url: "/index.php/aipic/Pic/get_all_list",
//     method: "get",
//     params
//   });
// };

//获取我的投稿列表
export const get_share_list = params => {
  return request({
    url: "/index.php/aipic/Pic/get_share_list",
    method: "get",
    params
  });
};

//根据ID获得图片详情(只能自己)
export const get_my_image_to_id = params => {
  return request({
    url: "/index.php/aipic/Pic/get_to_id",
    method: "get",
    params
  });
};

//根据ID获得图片详情
export const get_image_to_id = params => {
  return request({
    url: "/index.php/aipic/Publicpic/get_to_id",
    method: "get",
    params
  });
};


//添加/取消隐藏
export const add_hide = params => {
  return request({
    url: "/index.php/aipic/Pic/add_hide",
    method: "get",
    params
  });
};

//图片点赞/取消	
export const piclikes = params => {
  return request({
    url: "/index.php/aipic/Publicpic/piclikes",
    method: "get",
    needToken: true,
    params
  });
};

//引擎列表
export const engine_list = params => {
  return request({
    url: "/index.php/aipic/Ai/engine_list",
    method: "get",
    params
  });
};

//获取引擎消耗
export const get_consume = params => {
  return request({
    url: "/index.php/aipic/Ai/get_consume",
    method: "get",
    params
  });
};

//添加/取消投稿
export const add_together = params => {
  return request({
    url: "/index.php/aipic/Pic/add_together",
    method: "get",
    params
  });
};

//广告图列表
export const get_banner = params => {
  return request({
    url: "/index.php/aipic/Userin/get_banner",
    method: "get",
    params
  });
};

//再来一张
export const once_more = params => {
  return request({
    url: "/index.php/aipic/Ai/once_more",
    method: "get",
    params
  });
};

//关注图片列表
export const care_list = params => {
  return request({
    url: "/index.php/aipic/Publicpic/care_list",
    method: "get",
    params
  });
};

//根据ID删除图片
export const del_to_id = params => {
  return request({
    url: "/index.php/aipic/Pic/del_to_id",
    method: "get",
    params
  });
};

//点赞图片列表
export const like_list = params => {
  return request({
    url: "/index.php/aipic/Publicpic/like_list",
    method: "get",
    params
  });
};

//根据用户id获取列表
export const get_share_list_by_id = params => {
  return request({
    url: "/index.php/aipic/Userin/get_share_list",
    method: "get",
    params
  });
};

//画同款
export const same = params => {
  return request({
    url: "/index.php/aipic/Ai/same",
    method: "get",
    params
  });
};

//下载图片
export const downloadImg = params => {
  return download("/index.php/aipic/Pic/download",params,new Date().getTime());
};



//新百度分辨率列表v1
export const resolution_v1 = params => {
  return request({
      url: "/index.php/aipic/Ai/resolution_v1",
      method: "get",
      params
  });
};


//新百度分辨率列表v2
export const resolution_v2 = params => {
  return request({
      url: "/index.php/aipic/Ai/resolution_v2",
      method: "get",
      params
  });
};

//百度V1文生图
export const txt2imgv1 = params => {
  return request({
      url: "/index.php/aipic/Ai/txt2imgv1",
      method: "get",
      params
  });
};

//百度V2文生图
export const txt2imgv2 = params => {
  return request({
      url: "/index.php/aipic/Ai/txt2imgv2",
      method: "get",
      params
  });
};

//百度V1图文生图
export const imgtxt2imgv1 = params => {
  return request({
      url: "/index.php/aipic/Ai/imgtxt2imgv1",
      method: "get",
      params
  });
};

//百度V2图文生图
export const imgtxt2imgv2 = params => {
  return request({
      url: "/index.php/aipic/Ai/imgtxt2imgv2",
      method: "get",
      params
  });
};