import request from "@/utils/request";


// 发送短信
export const sms = params => {
  return request({
    url: "/index.php/aipic/User/sms",
    method: "get",
    params
  });
};

// token生成
export const token = params => {
  return request({
    url: "/index.php/aipic/User/token",
    method: "get",
    params
  });
};


// 注册&登录
export const randl = data => {
  return request({
    url: "/index.php/aipic/User/randl",
    method: "post",
    data
  });
};

// 微信登录
export const wechatLogin = params => {
  return request({
    url: "/index.php/aipic/User/wechatLogin",
    method: "get",
    params
  });
};

// 购买生成数量
export const n_buy = params => {
  return request({
    url: "/index.php/aipic/Ai/n_buy",
    method: "get",
    params
  });
};

// 根据ID获得用户详情
export const get_user_to_id = params => {
  return request({
    url: "/index.php/aipic/Userin/get_to_id",
    method: "get",
    params
  });
};

// 获取用户数据
export const get_data = params => {
  return request({
    url: "/index.php/aipic/User/get_data",
    method: "get",
    params
  });
};

//价格列表
export const buy_list = params => {
  return request({
    url: "/index.php/aipic/Ai/buy_list",
    method: "get",
    params
  });
};

//修改头像
export const change_head = params => {
  return request({
    url: "/index.php/aipic/User/change_head",
    method: "get",
    params
  });
};

//修改用户名
export const change_name = params => {
  return request({
    url: "/index.php/aipic/User/change_name",
    method: "get",
    params
  });
};

//获得最新支付结果
export const get_last_pay = params => {
  return request({
    url: "/index.php/aipic/User/get_last_pay",
    method: "get",
    params
  });
};

//获得微信登录信息
export const get_wxl_intel = params => {
  return request({
    url: "/index.php/aipic/User/get_wxl_intel",
    method: "get",
    params
  });
};

//微信号绑定
export const wechatBind = params => {
  return request({
    url: "/index.php/aipic/User/wechatBind",
    method: "get",
    params
  });
};
