import { getUserInfo, setUserInfo, getLocalStorage, setLocalStorage } from "../../utils/auth";
import { get_data } from "@/api/account";

const state = {
  userInfo: getUserInfo(),
  showLogin: false
};
const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_SHOW_LOGIN: (state, showLogin) => {
    state.showLogin = showLogin;
  }
};
const actions = {
  setUserInfo({
    commit
  }, data) {
    commit("SET_USER_INFO", data);
    setUserInfo(data)
  },
  setShowLogin({
    commit
  }, data) {
    commit("SET_SHOW_LOGIN", data);
  },
  getUserInfo({
    commit
  }, data) {
    
    return new Promise((resolve, reject) => {
      const userInfo = getUserInfo()
      if (!userInfo.id) {
       reject()
       return
      }

      get_data({
        cid: userInfo.id
      }).then((e) => {
        const { data, code } = e
        if (code == "200") {
          const info = Object.assign(userInfo,data)
          commit("SET_USER_INFO", info);
          setUserInfo(info)
          resolve(info)
        }
      });
    })
  }
};

export default {
  // namespaced: true,
  state,
  mutations,
  actions
};
