import axios from "axios";
import store from "../store";
import { getUserInfo } from "./auth";
import toast from '../plugins/toast'
const Qs = require('qs');
// import { getToken } from "@/utils/auth";
// import Tool from "@/utils/Tool";
// import host from "@/config";

let oneceRefreshToken = false;
const cashRequest = {}; // 缓存请求

const service = axios.create({
  // baseURL: process.env.VUE_APP_HOST_NAME + "/api",
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: "/api",
  timeout: 60000
});

service.interceptors.request.use(
  config => {
    // config.baseURL = config.proxy ? host[config.proxy] : host["coupon-system"];
    const userInfo = getUserInfo()
    if (userInfo.token) {
      if (config.method === "post") {
        if(!config.data){
          config.data = {}
        }
        if (!config.data.uid && config.data.uid !== 0) {
          config.data.uid = userInfo.id
        }
        config.data.token = userInfo.token
      } else if(config.method === "get") {
        if(!config.params){
          config.params = {}
        }
        if (!config.params.uid && config.params.uid !== 0) {
          config.params.uid = userInfo.id
        }
        config.params.token = userInfo.token
      }
      
    }
    config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    if (config.method === "post") {
      config.data = Qs.stringify(config.data)
    }

    // const reg = new RegExp(config.baseURL, "ig");
    // const cfKey = config.url.replace(reg, "");
    // cashRequest[cfKey] = config;

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    const userInfo = getUserInfo()
    if (response.data.code != 200) {
      if (response.data.code == 401) {
        store.dispatch("setUserInfo", {}).then(() => {
          // location.reload();
          // setTimeout(e => {
          //   location.href = '/'
          // },500)
          // if (userInfo.id) {
            // location.href = '/'
          // } else {
            // store.dispatch('setShowLogin',true)
          // }
          
          // Vue.$router.push({
          //   path: '/'
          // })
        });
        return Promise.reject(response.data)
      } else {
        toast(response.data.msg)
        return Promise.reject(response.data)
      }
    }
    return response.data;
  },
  error => {
    if (error && error.response) {
    }
    return Promise.reject(error);
  }
);

// 通用下载方法
export function download(url, params, filename) {
  return service.post(url, params, {
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    timeout: 0
  }).then(async (data) => {
    let eleLink = document.createElement('a')
        eleLink.download = filename
        eleLink.style.display = 'none'
        let blob = new Blob([data], {type: "image/png"})
        const hrefUrl = URL.createObjectURL(blob);
        eleLink.href = hrefUrl
        // 触发点击
        document.body.appendChild(eleLink)
        eleLink.click()
        // 然后移除
        document.body.removeChild(eleLink)
      // let dom = document.createElement('a')
      //   let url = window.URL.createObjectURL(blob)
      //   dom.href = url
      //   dom.download = filename
      //   dom.style.display = 'none'
      //   document.body.appendChild(dom)
      //   dom.click()
      //   dom.parentNode.removeChild(dom)
      //   window.URL.revokeObjectURL(url)
  }).catch((r) => {
    console.error(r)
  })
}

export default function(e){
  if(e.needToken){
    const userInfo = getUserInfo()
    if (!userInfo.token) {
      store.dispatch('setShowLogin',true)
      return Promise.reject();
    }
  }
  return service(e)
};
